.gcrff-intro {
    border-radius: .5rem;
    background-color: #e9ecef;
    padding: 1%;
    margin: 3% 5%;
    border: 1px solid rgba(149,14,33,.125);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    font-size: 1.5rem;
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
    text-align: center;
}

.dt-center-search {
    background-color:red;
    width: 40%;

}

.dataTables_filter  {
    padding-left: 25%;
    float: left !important;
    margin-bottom:10px;
}

.dataTables_filter label input {
    width: 500px;
}

select {
    padding: 3px;
}

.cell-expand-icon {
    float: right;
    vertical-align: text-top;
}

.filters {
    width:100%;
}

ul li {
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
    font-size: 1.1rem;
    margin: 1%;
    text-align: left;
    color: #000000;
    line-height: 1.5;
}

