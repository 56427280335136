$white-color: #FFFFFF !important;
$black-color: #000000 !important;
$primary-color: #c10e21 !important;
$secondary-color: #495057 !important;
$dark-color: #222328 !important;
$color-e3: #E3E3E3 !important;
$border-color: #c4cacf !important;
$color-f8: #f8f8f8 !important;
$color-light-green: #bee5eb !important;

$color-70: #707070 !important;
$light-color: #E7EAF2 !important;
$sidebar-bg: #F8F9FB !important;
$color-71: #717171 !important;
$blue-color: #252572 !important;
$button-shadow: rgba(255, 140, 52, 0.22) !important;

$transparent: transparent !important;

$primary-font: 'Montserrat',
sans-serif !important;

$zero-value: 0 !important;
$none-value: none !important;
$full-width: 100% !important;
$block-display: block !important;



$color-56: #565656 !important;
$white-color-75: rgba(255, 255, 255, 0.75) !important;
$bg-color: #343a40 !important;
$color-ee: #eeeeee !important;
$color-99: #999999 !important;


$zero-value: 0 !important;
$none-value: none !important;
$full-width: 100% !important;



$button-shadow: rgba(255, 140, 52, 0.22) !important;

$transparent: transparent !important;

$primary-font: 'Montserrat',
sans-serif !important;

$zero-value: 0 !important;
$none-value: none !important;
$full-width: 100% !important;
$block-display: block !important;

$dashboard-primary: #D00505 !important;
$container-fluid-pl: 14% !important;