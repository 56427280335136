.inactive img, .inactive p, .inactive a, .inactive button {
    z-index: -1;
}

.inactive {
    background-color: rgba(227, 227, 227, 0.75);
    border: 1px solid rgba(225, 17, 39, 0.1);
}

.status-message {
    position: absolute;
    font-size: 1.5em;
    font-weight: bold;
    font-style: italic;
    color: #6d6e71;
    /*background-color: #6D6E71;*/
    -webkit-text-stroke: 1px rgba(255,255,255, 0.25);
    /*text-decoration-color: red;*/
}


.ribbon-wrapper-green {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;

}

.ribbon-green {
    font: bold 1.2rem Sans-Serif;
    color: #333;
    text-align: center;
    text-shadow: rgba(255,255,255,0.5) 0 1px 0;
    -webkit-transform: rotate(30deg);
    -moz-transform:    rotate(30deg);
    -ms-transform:     rotate(30deg);
    -o-transform:      rotate(30deg);
    position: absolute;
    padding: 7px 0;
    left: 45%;
    top: 10%;
    width: 70%;
    background-color: #BFDC7A;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BFDC7A), to(#8EBF45));
    background-image: -webkit-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image:    -moz-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image:     -ms-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image:      -o-linear-gradient(top, #BFDC7A, #8EBF45);
    /*color: #6a6340;*/
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.3);
    -moz-box-shadow:    0 0 3px rgba(0,0,0,0.3);
    box-shadow:         0 0 3px rgba(0,0,0,0.3);
    /*border-bottom:   2px solid #6e8900;*/
}



