
/* collapsible content styling */
.collapsible {
    cursor: pointer;
    width: 100%;
    padding:0;
    margin:0;
    margin-block-start:0.1rem;
    margin-block-end:0.1rem;
    background-color: transparent;
    border:none;
}

.collapsible a {
    width:100%;
}

.collapsible a.focus, .collapsible a:focus, .collapsible.focus, .collapsible:focus {
    box-shadow: none !important;
    outline:none !important;
    text-decoration-line: none;
}

.collapsible.focus, .collapsible:focus {
    outline: #b6c1fa solid 0.15em;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.collapsible .fa, .collapsible .fas {
    margin-left:10px;
    margin-right: 10px;
}

.collapsible .h2 i.fa-angle-double-down {
    font-weight: lighter;
}

.collapsible i.fas {
    padding-top:2%;
    padding-right:1%;
    font-size: large;
    color:#C10E21;
    float:right;
}

.collapsible .fa-plus, .collapsible .fa-minus {
    float:right;
    padding-top:1%;
    padding-right:1%;
    display:inline-block;
    vertical-align: center;
}

