


.cards-section {
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem;
    row-gap: 1rem;
    justify-content: center;
}


.card {
    display: flex;
    /*flex: 1 1;*/
    word-wrap: break-word;
    background-clip: border-box;
    background-color: transparent;
    border-radius: .25rem;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    margin-bottom: 1%;
    min-width: 300px;
}

.data-resource-card, .docs-home-card, .dataset-doc-card, .about-card {
    /*min-width: 350px;*/
    min-height: 200px;

}

.data-resource-card-cursor{
    cursor: pointer;
}

.data-resource-card, .docs-home-card, .dataset-doc-card, .about-card {

    border: 1px solid rgba(149, 7, 27, 0.12);
    margin:0;
    border-radius: 0.25rem;
    height: 100%;
    width: 100%;
}

.card-content, .card-content img {
    align-self: center;
    max-height: 100%;
    max-width: 100%;


}


.card-content h2, .card-content h3 {
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
    line-height: 1.2;
    font-weight: 600;

}


.card-content p, .card-content li {
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
    font-weight: normal;
    font-size: 1.1rem;
    text-align: left;
    color: #000000;
    line-height: 1.5;
    margin: 1%;
}



.docs-home-card {
    text-align: center;
    align-self: center;
    width:100%;
    padding: 5% 3%;
    min-width:300px;
}

.docs-home-card i.fa {
    margin-bottom:5%;
    color:#C10E21;
    text-align: left;


}

.data-resource-card {
    display: flex;
    flex-wrap:wrap;
    flex-direction: column;
    justify-content: center;

}

.data-resource-card img {
    display: flex;
    position:absolute;
    border: none;
    background: transparent;

}

.dataset-doc-card {
    height: 100%;
    overflow: auto;
    align-content: center;
    background: url("/assets/documentation/images/molecules_data_sm.png") repeat-y;
    padding: 2% 5% 2% 4.25rem;
    border: 1px solid rgba(149, 7, 27, 0.12);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    text-align: left;
    font-size: 1.2em;
    margin: 2%;
}

.dataset-doc-card .card-content i.fa {
    margin: 0 0.25em 0.25em .25em;
    vertical-align: center;
    font-size:xx-large;
    display: inline;
}

.dataset-doc-card .card-content h3, .dataset-doc-card .card-content i.fa {
    display:inline-flex;
    color: #C10E21;
    margin-left: 0.25em;
}

.dataset-stat-card {
    display: flex;
    flex-wrap:wrap;
    flex-direction: column;
    row-gap: .25rem;
    max-width: 300px;
    max-height: 150px;
    min-width: 250px;
    text-align: center;
}

.about-card {
    text-align: center;
    align-self: center;
    border-radius: .25rem;
    padding: 4%;

}

