
h6 {
    color: #6D6E71;
}

a {
    color: #3b55e6;
}

a:hover{
    text-decoration-line: underline;
    color:#334CD5;
}



.header-banner {
    background-color:#FFFFFF;
    width:100%;
    padding: 1%;
    color: #C10E21;
    vertical-align: middle;
    font-size: 1.2rem;
    text-align:center;
    border-top: .1375rem solid #C10E21;
    border-bottom: .1375rem solid #C10E21;
}
.header-banner .primary-btn {
    margin-left:15px;
    font-size: 1.2rem;
}





.dropdown-menu li:hover  {
    border-bottom: 2px solid #e9ecef;
    /*background-color: #e9ecef;*/

}

.dropdown-menu li:hover a {
    text-decoration-line: none;
    color: #C10E21;
}
.dropdown-menu {
    padding:10px;
}

.dropdown-menu li a {
    margin:1%;
    font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
    color: #6D6E71;
    white-space:nowrap;
}

.secondary-btn  {
    border: 0.15rem solid #C10E21;
    background-color: #ffffff;
    border-radius: 15rem;
    padding: 2px 15px;
    font-size: 16px;
    color: #C10E21;
    text-decoration: none;
}

.secondary-btn a {
    font-size: 16px;
    color: #C10E21;
}

.secondary-btn.focus, .secondary-btn:focus, .secondary-btn.active {
    outline: none !important;
    border: 3px solid #b6c1fa;
    color:#C10E21;
    text-decoration-line: underline;
    text-decoration-color: #C10E21;
    /*box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);*/
}

.secondary-btn:hover {
    background-color: #ffffff;
    color: #E11127;
    text-decoration-line: underline;
    text-decoration-color: #E11127;
    border: .15rem solid #E11127;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.secondary-btn li a:hover {
    border-bottom: 2px solid #e9ecef;
    background-color: transparent;
    color: #C10E21;
}


