
.faq-nav-dropdown {
    display: flex;
    padding:.25em .5em;
}

.faq-container {
    padding: 1%;
}


.faq-question  {
    display: inline-block;
    margin-left: 0;
    color: #000000;
    padding-top: 0;
    background-color: transparent;
    border: none;
    text-align: left;
}

.faq-question i.fa-caret-down, .faq-question i.fa-caret-right  {
    color:#3b55e6;
    font-size: large;
    float:left;
    padding:0 1em;
    vertical-align: top;
}

.faq-question a span {
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: left;
    display: block;
    overflow:hidden;
    color: #000000;
}

.faq-question a:hover, .faq-question a:hover span, .faq-question a:hover i {
    text-decoration: none;
    color:#C10E21;
}
.faq-answer {
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
    font-size: 1.1rem;
    text-align: left;
    color: #6D6E71;
    padding-left:10%;
    margin:2px;
    /*display: none;*/
}

a.faq-anchor {
    display: block;
    position: relative;
    top: -200px;
    visibility: hidden;
}

